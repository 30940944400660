* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

:root {
  --red-color: #db1516;
  --black-color: #000000;
  --gray-color: rgba(0, 0, 0, 0.6);
  --white-color: white;
  --green-color: #008000;
}

#section-padding {
  padding: 75px 0px;
  /* padding-top: 95px;
  padding-bottom: 0px; */
}

.back-img-main {
  background-image: url(./Component/asset/img/Home/background-img.png);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-attachment: fixed;
}

.heading-section h3 {
  text-align: center;
  font-size: 37px;
  font-weight: 700;
  color: var(--red-color);
}

.heading-section p {
  text-align: center;
  font-size: 15px;
  color: var(--gray-color);
}

a.order-now-button {
  padding: 8px 20px;
  font-size: 12px;
  color: #ffffff;
  background: var(--red-color);
  border-radius: 50px;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
a.order-now-button:hover {
  background: #ffffff;
  color: var(--red-color);
}

.order-now-button {
  padding: 8px 20px;
  font-size: 12px;
  color: #ffffff !important;
  background: var(--red-color) !important;
  border-radius: 50px;
  text-decoration: none !important;
  transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}
.order-now-button:hover {
  background: #ffffff;
  color: var(--red-color);
}

.text-color-red {
  color: var(--red-color) !important;
  font-weight: 500;
}

.text-color-gray {
  color: var(--gray-color) !important;
  font-weight: 500;
}

.text-color-black {
  color: var(--black-color) !important;
  font-weight: 500;
}
.fs-7 {
  font-size: 13px;
}
.cursor-pointer {
  cursor: pointer;
}
/* Navbar Common */
/* ---------------------------------*/

.navbar {
  position: fixed;
  top: 10px;
  left: 10%;
  border-radius: 50px;
  width: 80%;
  background-color: #fff2f2;
  z-index: 1;
}

.logo-img img {
  max-height: 54px;
  padding-left: 15px;
}

.login-btn {
  border: 2px solid black;
  border-radius: 50px;
  padding: 0px 30px;
  margin: 0px 15px;
}

.nav-item {
  padding: 0px 15px !important;
}

.nav-link {
  padding: 0px;
  color: var(--black-color);
}

.login-button {
  padding: 5px 35px;
}

.cart-trolley-link {
  position: relative;
  padding: 0px !important;
  margin: 0px 27px;
}

.cart-trolley {
  position: relative;
  font-size: 25px;
}

.cart-total-item {
  width: 8px;
  height: 8px;
  position: absolute;
  background-color: var(--red-color);
  color: var(--gray-color);
  border-radius: 50%;
}

.cart-total {
  width: 25px;
  height: 25px;
  position: absolute;
  background-color: var(--green-color);
  color: var(--white-color);
  border-radius: 50%;
  display: grid;
  place-items: center;
  top: -27%;
  left: 67%;
}

.login-btn:hover {
  background-color: var(--red-color);
}

.login-button:hover {
  color: var(--white-color) !important;
}

/* Home Page*/
/* ---------------------------------*/

.top-back-ground {
  background: url(./Component/asset/img/Home/Group\ 1651.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 115vh;
}

.white_pad {
  position: relative;
  right: 0px;
  bottom: 0px;
}

.white_pad img {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.home-heading h2 {
  font-size: 30px;
  color: var(--red-color);
  text-align: left;
}

.home-heading p {
  font-size: 16px;
  padding: 6px 0px;
  color: var(--black-color);
}

a.learn_more2:hover {
  background: #222222;
}

.back-ground-ch {
  width: 70%;
  display: inline-block;
}

.header-img {
  filter: drop-shadow(0 3.2rem 2.25rem rgba(219, 21, 22, 0.5));
}
.home-heading {
  /* padding-top: 100px; */
  align-items: center;
}

/* About Page */
/* ---------------------------------*/

.about-img {
  width: 35%;
  display: inline-block;
}

.about-content h3 span {
  color: var(--red-color);
}

.about-content p {
  font-size: 16px;
  color: var(--gray-color);
}

.hover-effect {
  display: block;
  overflow: hidden;
  position: relative;
}

.text-about {
  text-align: justify;
}

.about-page-img img {
  width: 85%;
  display: inline-block;
}

/* Categories */
/* ---------------------------------*/

.circle {
  width: 150px;
  height: 150px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgb(217, 201, 201) 0px 0px 55px 0px;
}
.circle:hover {
  box-shadow: rgba(219, 21, 22, 0.5) 0px 0px 55px 0px;
}

.fresh-chicken .heading {
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  padding-top: 15px;
}

.fresh-chicken .description {
  font-size: 15px;
  font-weight: 500;
  text-align: center;
  color: var(--black-color);
  padding: 10px;
}

/* Card */
/* ---------------------------------*/

.heading-section span {
  color: var(--black-color);
}

.card-icon {
  background-color: var(--card-background-color);
  border-radius: 8px;
  margin-top: 20px;
  padding: 3px 0px;
}

.card-shadow {
  box-shadow: rgba(0, 0, 0, 0.25) 0px 22px 70px 4px;
  border: none;
  border-radius: 19px;
}

/* Halal */
/* ---------------------------------*/
.halal-img-grid {
  display: flex;
  justify-content: space-between;
}

.halal-img {
  width: 85%;
  display: inline-block;
}

/* Footer */
/* ---------------------------------*/
.background-color-footer {
  background-color: #ffe5e5;
}

.footer-logo {
  width: 55%;
}

.text-color-gray span {
  color: var(--red-color);
  font-weight: 700;
}

.footer-icon {
  color: var(--gray-color);
}

.footer span {
  font-size: 14px;
  margin-left: 8px;
  color: var(--gray-color);
}

.social-media-icon {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.hr {
  background-color: var(--black-color);
  height: 1px;
  margin: 10px 0px;
}

/* Order Page */
/* ---------------------------------*/
.order-category-chicken {
  display: flex;
  align-items: center;
  width: 11%;
  justify-content: space-between;
  padding: 15px 0px;
}

.order-category-desi-chicken {
  display: flex;
  align-items: center;
  width: 18%;
  justify-content: space-between;
  padding: 25px 0px;
}
.form-check-input:checked {
  background-color: var(--green-color);
  color: var(--white-color);
}
.form-check {
  padding-left: 10px;
}
/* Sign Up Page */
/* ---------------------------------*/

.sign-up-button {
  padding: 10px 75px;
  background-color: var(--red-color);
  color: var(--white-color);
  border-radius: 10px;
  text-decoration: none;
}

/* Login */
/* ---------------------------------*/
.gradient-custom-2 {
  background: linear-gradient(90deg, #ffe5e5, #d0a6a6);
}
/* background-color: #ffe5e5; */

/* Customer  */
/* ---------------------------------*/
.costumer-wrapper {
  max-height: 100%;
  display: flex;
  gap: 10px;
}

/* .card-slide{
  margin: 0.5rem;
  width: calc(100%/3);
} */
.costumer-single {
  margin-top: 50px;
  margin-bottom: 20px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  padding: 30px;
  overflow: hidden;
}

.costumer-card {
  margin-right: 30px;
}

.costumer-content {
  gap: 15px;
}

.costumer-author-img img {
  border-radius: 50px;
  width: 78px !important;
  height: 78px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
}

.costumer-quote p {
  margin: 15px 0;
}

.costumer-rate {
  color: #ff7900;
}

/* Account  */
/* ---------------------------------*/

.wrapper {
  display: flex;
}

.indicator {
  border-right: 1px solid var(--black-color);
}

.indicator li {
  font-size: 14px;
  cursor: pointer;
  font-weight: 600;
  color: var(--red-color);
  margin: 25px 15px;
  padding: 6px;
  display: block;
}

.indicator li:hover {
  border-radius: 8px;
  color: var(--white-color);
  background-color: var(--red-color);
  padding: 6px;
}

.indicator li.active {
  border-radius: 8px;
  color: var(--white-color);
  background-color: var(--red-color);
  padding: 6px;
}

.content li {
  display: none;
}

.content li.active {
  display: block;
}

.content li h1 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--red-color);
  margin-bottom: 0.75rem;
}

.content li p {
  margin-bottom: 0.5rem;
  color: var(--dark-grey);
  font-size: 0.875rem;
}
.background-color-main {
  background-color: #ffe5e5;
  height: 100px;
  /* padding-top: 30%; */
}

.add-new-button {
  /* background-color: var(--red-color); */
  color: var(--red-color) !important;
  border-radius: 10px;
  padding: 6px 25px;
}

.add-new-button:hover {
  color: var(--white-color) !important;
  background-color: var(--red-color) !important;
  /* border-color: var(--bs-btn-hover-border-color); */
}

.text {
  color: var(--red-color) !important;
  font-weight: 700;
  font-size: 25px;
}

.modal-header {
  border-bottom: none !important;
}

.modal-footer {
  border-top: none !important;
}

.form-control {
  /* border: none !important; */
  font-size: 14px !important;
}

.form-label {
  font-size: 16px !important;
}

.primary {
  background-color: var(--red-color) !important;
}

.cancel {
  background-color: #908e9b !important;
}

/* Description  */
/* ---------------------------------*/

.description-carousel-img {
  margin-left: auto;
  margin-right: auto;
  width: 439.59px;
  height: 439.59px;
}

.description-carousel-img img {
  border-radius: 10px;
}

.carousel-indicators [data-bs-target] {
  height: 100px;
  width: 82px;
  border-radius: 10px;
  border: 0;
}

.carousel-control-next,
.carousel-control-prev {
  bottom: 50px;
}

.carousel-control-next-icon,
.carousel-control-prev-icon {
  background-image: none;
}

.description-text h5 {
  font-family: karma;
  font-size: 20px;
  font-weight: 600;
}

.description-text p {
  color: var(--gray-color);
}

.add-to-cart-button {
  padding: 10px 53px;
  color: var(--red-color);
  border: 2px solid red;
  border-radius: 10px;
  text-decoration: none;
  background-color: var(--white-color);
  cursor: pointer;
  display: flex;
  align-items: center;
}

.add-to-cart-button:hover {
  background-color: var(--red-color);
  color: var(--white-color);
}

.btn {
  margin-top: 10px;
  border: 0;
  background-color: #f1f1f1;
  color: #db1516;
}

.form-control {
  border: 0;
  margin-top: 10px;
  background-color: #f1f1f1;
}

.description-heading {
  font-size: 34px;
  font-family: karma;
  font-weight: 700;
}

.product-price {
  gap: 15px;
}

.price-1 {
  font-size: 34px;
  font-weight: 700;
}

.price-2 {
  font-size: 30px;
  font-weight: 600;
  color: var(--gray-color);
}

.price-off {
  font-size: 28px;
  font-weight: 600;
  color: var(--red-color);
}
.product-quantity-btn {
  border: 1px solid var(--red-color);
  border-radius: 10px;
}
.price-heading {
  font-size: 20px;
  font-family: karma;
  font-weight: 600;
}

.description {
  color: var(--gray-color);
}

.Product-quantity {
  font-size: 20px;
  font-weight: 700;
}

.product-offer {
  font-size: 20px;
  font-family: karma;
  font-weight: 600;
}

.description-card-heading {
  font-size: 40px;
}

.offer-card-1 {
  /* width: 200px;
  height: 97px; */
  border: 1px solid var(--red-color);
  border-radius: 10px;
  font-weight: 400;
  font-size: 15px;
  font-family: Arial;
}

.offer-card-2 {
  /* width: 200px;
  height: 97px; */
  border: 1px solid var(--red-color);
  border-radius: 10px;
  font-weight: 500;
  font-size: 15px;
}

.offer-card-3 {
  width: 145px;
  height: 122px;
  border: 1px solid var(--red-color);
  border-radius: 10px;
  color: var(--black-color);
  text-decoration: none;
  font-weight: 400;
  font: Arial;
  font-size: 16px;
}
.offer-card-terms {
  text-decoration: none;
  color: var(--red-color);
  font-size: 14px;
  font-weight: 400;
}
.similar-prooduct-img img {
  border-radius: 10px;
  width: 100% !important;
}

.similar-product-name {
  font-size: 24px;
  font-weight: 500;
}
.similar-product-price {
  font-weight: 600;
  font-size: 20px;
}
.similar-product-price del {
  color: var(--red-color);
  margin-left: 10px;
}

.product-details {
  padding: 20px;
}

.price-details {
  margin-bottom: 20px;
}

.product-image {
  width: 439.59px;
  height: 439.59px;
}
.add-ot-cart {
  display: flex;
  justify-content: end;
}
.buy-now {
  display: flex;
  justify-content: start;
}
.description-text {
  margin-left: 50px;
}

/* Cart Page */
/* ---------s ----------- */
.cart-img {
  height: 104px;
  width: 153px;
}
.shopping-empty {
  text-align: center;
}
.shopping-empt-icon {
  display: flex;
  justify-content: center;
}

/* Payment */

.alert-icon {
  /* position: absolute;
  left: 50%;
  bottom: 90%; */
  color: #71c341;
}
.alert-button {
  padding: 10px 100px;
  background-color: #71c341;
  color: var(--white-color);
}
.alert-button:hover {
  color: #71c341;
  background-color: var(--white-color);
}

/* 404 error page */

.error-pg {
  background: #ffe5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  font-family: "Poppins", sans-serif;
}
.error__wrapper {
  text-align: center;
}
.error__wrapper h1 {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  color: #fff;
  margin: 0;
}

.error__wrapper h1,
.error__wrapper p {
  color: var(--black-color);
}

.error__wrapper p {
  font-size: 1.5rem;
  margin-top: 0;
}
.error__wrapper img {
  max-width: 130px;
}

.error__wrapper a {
  background: #fff;
  display: inline-block;
  text-decoration: none;
  color: #000;
  padding: 0.5rem;
  border-radius: 20px;
  font-size: 0.875rem;
  margin-top: 3rem;
  transition: 0.3s all ease-in;
  border: 1px solid #fff;
}

.error__wrapper a:hover {
  background: var(--red-color);
  color: #fff;
}
.dropdown:hover .dropdown-menu {
  display: block;
  margin-top: 0; /* remove the gap so it doesn't close */
}
.whatsapp-sect {
  position: fixed;
  right: 5%;
  z-index: 999;
  margin-top: 13px;
  width: auto;
  border-radius: 55px;
  border: 0;
  bottom: 20px;
  background-color: #4bed2f;
  padding: 1px 4px;
}
.whatsapp-sect svg {
  font-size: 3.5rem;
}

.header-content .title {
  padding-top: 0px !important;
  padding-left: 60px;
}

/* Media Query */
/* --------------------------------------------------------------------------------------------------*/

@media screen and (max-width: 992px) {
  .nav-item {
    padding: 15px 0px !important;
  }

  .cart-total {
    top: 1px;
    right: 18px;
  }

  .login-btn {
    margin: 6px 15px;
  }
  .button-img {
    display: flex;
    margin-right: 5px;
  }
  .add-ot-cart {
    display: flex;
    justify-content: center;
  }
  .buy-now {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .halal-img-grid {
    display: grid;
    grid-template-columns: auto auto auto;
    /* padding: 10px; */
  }

  .social-media-icon {
    width: 100%;
    display: flex;
    /* justify-content: space-between; */
    justify-content: space-around;
  }
  .description-text {
    display: flex;
    justify-content: center;
    margin-left: 25px;
  }
  .costumer-wrapper {
    display: block;
  }
}

@media screen and (max-width: 576px) {
  .wrapper {
    flex-direction: column;
  }

  .indicator {
    border-right: none;
  }
  .costumer-card {
    margin-right: 0px !important;
  }
}

@media screen and (max-width: 490px) {
  .halal-img-grid {
    display: block;
    width: 40%;
    margin: 0px auto;
  }
}

.nav-link.active {
  border-bottom: 1.5px solid var(--red-color);
  padding: 4px;
}

@media only screen and (min-width: 320px) and (max-width: 992px) {
  .product-detail-head,
  .product-detail-hr {
    display: none !important;
  }
  .home-heading {
    padding-top: 100px;
    align-items: center;
  }
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .top-back-ground {
    background-repeat: no-repeat;
    background-size: contain !important;
    background-position: 0rem 30rem !important;
    height: 111vh !important;
    position: relative;
    top: 0rem;
    z-index: 999;
  }

  .header-content .title {
    padding-top: 0px !important;
    padding-left: 24px;
  }
  .header-content {
    background-color: #ffe5e5;
    height: 35rem;
  }
}
@media only screen and (min-width: 400px) and (max-width: 584px) {
  .top-back-ground {
    background-repeat: no-repeat;
    background-size: contain !important;
    background-position: 0rem 31rem !important;
    height: 95vh !important;
    position: relative;
    top: 0rem;
    z-index: 999;
  }
  .header-content .title {
    padding-top: 0px !important;
    place-items: center;
    display: grid;
  }
  .header-content {
    background-color: #ffe5e5;
    height: 42rem;
    margin: 0 auto;
    max-width: 1200px;
  }
}
@media only screen and (min-width: 584px) and (max-width: 768px) {
  .top-back-ground {
    background-repeat: no-repeat;
    background-size: contain !important;
    background-position: 0rem 38rem !important;
    height: 120vh !important;
    position: relative;
    top: 0rem;
    z-index: 999;
  }
  .header-content .title {
    padding-top: 0px !important;
    place-items: center;
    display: grid;
  }
  .header-content {
    background-color: #ffe5e5;
    height: 42rem;
    margin: 0 auto;
    max-width: 1200px;
  }
}
/* .form-control{
  background-color: #fff !important;
} */